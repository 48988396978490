@import "~bootstrap/scss/bootstrap";

$darkRed: #e50058;
$darkBlue: #5ecef4;
$black: #343a40;

#root {
  background-color: $black;
  .myContainer {
    max-width: 1200px;

    // NAVBAR
    & .navbar-container {
      height: 70px;
      width: 100%;
      border-bottom: 2px solid #e50058;
      & .navbar{
        height: 100%;
      }
      @media (max-width: 765px) {
        .navbar{
          justify-content: center;
        }
      }
    }
    // END NAVBAR
    // FORM

    & .myForm {
      margin-top: 20px;
      & .form-control:focus {
        border-color: $darkRed;
      }
      & .myLabel {
        color: $darkBlue;
      }
      & .inputAndButton {
        display: flex;
        margin-top: 12px;
        width: 100%;
        & .myInput {
          color: $black;
        }
      }
    }
    // END FORM
    // LOADING
    .loadingLabel{
      color: $darkBlue;
      margin-top: 20px;
    }
    // END LOADING
    // TABLE
    & .cardContainer {
      display: grid;
      grid-template-columns: 34% 33% 33%;
      align-items: center;
    }
    @media (max-width: 800px) {
      .cardContainer{
        grid-template-columns: 50% 50%;
      }
    }
    @media (max-width: 550px) {
      .cardContainer{
        grid-template-columns: 100%;
      }
      .panel{
        width: 100% !important;
      }
      .myLabel,
      .myInput{
        text-align: center;
      }
      td,th{
        padding: 0.3rem;
      }
      h4{
        font-size: 1.5rem;
      }
    }
    @media (max-width: 400px) {
      h4{
        font-size: 1.2rem;
      }
      .navbar-brand{
        font-size: 16px;
      }
      .myLabel{
        font-size: 1.8rem;
        text-align:center;
      }
      .panel-heading{
        font-size: 14px;
      }
    }
    & .panel {
      margin-top: 40px;
      background-color: white;
      width: 90%;
      border: 1px solid transparent;
      border-color: #ddd;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      & .panel-heading {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        background-color: $darkBlue;

        & .searchBtnContainer {
          float: right !important;
          margin-top: 10px;
          padding: 6px 12px;
          border: 1px solid white;
          background-color: $darkRed;
          border-radius: 4px;
          & svg {
            color: white;
            display: inline-block;
            margin-bottom: 0;
            font-weight: 400;
            text-align: center;
            vertical-align: middle;
            background-image: none;
            border: 1px solid transparent;
            white-space: nowrap;
            font-size: 1.1rem;
            line-height: 1.42857143;
            border-radius: 4px;
          }
        }
      } //end of panel heading
      & .globalCardHeading{
        background-color: $black;
        color: white;
        & .searchBtnContainer{
          background-color: $darkRed;
        }
      }
    } //end of panel
    .globalPanel{
      background-color: $darkBlue;
    }
    // END TABLE
  } //End of container
  // FOOTER
  & .myfooter {
    color: white;
    margin-top: 50px;
    & .footerA {
      color: $darkBlue;
    }
  }
  // END FOOTER
}

